import Glide from '@glidejs/glide'
import './partials/header';
import './partials/header-nav';
import './partials/mobile-menu';
import './pages/login';
import './pages/admins';
import './pages/arbitration';
import './pages/business';
import './pages/pricing';
import {redirectOrLogin} from './helpers';
import {appUrl} from './constants';
import './carrotquest'
import {AffiliateReferrerTracker} from "./affiliate-referrer-tracker";
import {SessionInitializer} from "./session-initializer";

function initSlider() {
    const slider = document.querySelector('.glide');

    if (slider !== null) {
        new Glide('.glide', {
            dots: true,
            autoplay: 5000,
            animationDuration: 1000,
            gap: 0
        }).mount()
    }
}

function handleEnterOrLoginButtons() {
    document.querySelectorAll('.js-hook__enter-or-login').forEach((element) => {
        element.addEventListener('click', () => redirectOrLogin(appUrl));
    })
}

document.addEventListener('DOMContentLoaded', function (event) {
    initSlider();
    handleEnterOrLoginButtons();
    new SessionInitializer().init().then(() => {
        new AffiliateReferrerTracker().handle();
    });
});
