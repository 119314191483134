export const PagesEnum = {
    ADMINS     : 'admins',
    ARBITRATION: 'arbitration',
    BUSINESS   : 'business',
    FEATURES   : 'features',
    LOGIN      : 'login',
    INDEX      : 'index',
    PRICING    : 'pricing'
};
Object.freeze(PagesEnum);
